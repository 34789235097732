/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './variables.scss';

.cc-revoke {
  display: none !important;
}

* {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-family: 'Montserrat', sans-serif !important;
}

html,
body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

app-spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 91px;
}

.justify-center-container {
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet-active {
  background-color: #e84e10 !important;
}

.swiper-button-next, .swiper-button-prev {
  color: white !important;

  &::after {
    font-size: 18px !important;
  }
}

.swiper-slide {
  height: auto !important;
}